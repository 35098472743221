<template>
	<div class="footer">
		<div class="actions">
			<div class="cursor-pointer second-col w-100" @click="handleToggleDetail">
				<span class="mr-1">{{ toggleDetail ? FormMSG(1, 'Show details') : FormMSG(2, 'Hide details') }}</span>
				<span v-if="toggleDetail"><chevron-down :size="18" /></span>
				<span v-else><chevron-up :size="18" /></span>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { ChevronDown, ChevronUp } from 'lucide-vue';

export default {
	name: 'ExpenseDetailFooter',
	mixins: [languageMessages],
	components: {
		ChevronDown,
		ChevronUp
	},
	computed: {},
	data() {
		return {
			toggleDetail: false
		};
	},
	methods: {
		handleToggleDetail() {
			this.toggleDetail = !this.toggleDetail;
			this.$emit('expense-detail-builder:toggle', this.toggleDetail);
		}
	}
};
</script>
